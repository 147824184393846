var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isUserAdmin)?[(_vm.removedContract)?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v("Contract at address "),_c('b',[_vm._v(_vm._s(_vm.removedContract))]),_vm._v(" has been successfully removed.")]):_vm._e(),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canImport),expression:"!canImport"}],attrs:{"dense":"","text":"","type":"warning"}},[_vm._v("Free plan users are limited to 10 synced contracts. Remove some contracts or "),_c('Upgrade-Link',{attrs:{"emit":true},on:{"goToBilling":_vm.goToBilling}},[_vm._v("upgrade")]),_vm._v(" to the Premium plan for more.")],1),_c('Import-Contract-Modal',{ref:"importContractModal"}),_c('Remove-Contract-Confirmation-Modal',{ref:"removeContractConfirmationModal",on:{"refresh":_vm.getContracts}})]:_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.contracts,"headers":_vm.headers,"sort-by":_vm.currentOptions.sortBy[0],"must-sort":true,"sort-desc":true,"server-items-length":_vm.contractCount,"footer-props":{
            itemsPerPageOptions: [10, 25, 100]
        },"item-key":"address"},on:{"update:options":_vm.getContracts},scopedSlots:_vm._u([(_vm.isUserAdmin)?{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"py-0",attrs:{"flat":"","dense":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.openImportContractModal()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-import")]),_vm._v("Import Contract ")],1)],1)]},proxy:true}:null,{key:"no-data",fn:function(){return [_vm._v(" No contracts found - "),_c('a',{attrs:{"href":"https://doc.tryethernal.com/getting-started/cli","target":"_blank"}},[_vm._v("Did you set up the CLI?")])]},proxy:true},{key:"item.tags",fn:function(ref){
        var item = ref.item;
return _vm._l((item.patterns),function(pattern,idx){return _c('v-chip',{key:idx,staticClass:"success mr-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.formatContractPattern(pattern))+" ")])})}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'contract',"hash":item.address}})]}},{key:"item.timestamp",fn:function(ref){
        var item = ref.item;
return [(item.timestamp)?[_c('v-tooltip',{attrs:{"top":"","open-delay":150,"color":"grey darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(item.timestamp),'MM/DD h:mm:ss A'))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.moment(item.timestamp).fromNow())+" ")])]:_vm._e()]}},(_vm.isUserAdmin)?{key:"item.remove",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openRemoveContractConfirmationModal(item.address)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }