var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"items":_vm.blocks,"sort-by":_vm.currentOptions.sortBy[0],"must-sort":true,"sort-desc":true,"server-items-length":_vm.blockCount,"hide-default-footer":_vm.dense,"hide-default-header":_vm.dense,"footer-props":{
        itemsPerPageOptions: [10, 25, 100]
    },"headers":_vm.headers},on:{"update:options":_vm.getBlocks},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No blocks found ")]},proxy:true},{key:"item.number",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":'/block/' + item.number}},[_vm._v(_vm._s(item.number))])]}},{key:"item.timestamp",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"my-2 text-left"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(item.timestamp),'MM/DD h:mm:ss A'))),_c('br'),_c('small',[_vm._v(_vm._s(_vm.moment(item.timestamp).fromNow()))])])]}},{key:"item.gasUsed",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.gasUsed.toLocaleString())+" ")]}},{key:"item.transactionNumber",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.transactionsCount)+" "+_vm._s(item.transactionsCount != 1 ? 'transactions' : 'transaction')+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }